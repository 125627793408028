import React, { Component } from 'react';
// import { HashRouter, Route, Switch } from 'react-router-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import LayoutContainer from './container/layout.container';


const loading = () => (
    <div id="ht-preloader">
        <div className="loader clear-loader">
            <div className="loader-inner">
                <svg viewBox="0 0 80 80">
                    <rect x="8" y="8" width="64" height="64"></rect>
                </svg>{' '}
            </div>{' '}
        </div>
    </div>
);

class App extends Component {
    render() {
        return (
            <>
                <Router>
                    <React.Suspense fallback={loading()}>
                        <div>
                            <Route
                                path="/"
                                name="Home"
                                render={props => (
                                    <LayoutContainer
                                        {...props}
                                        onUpdate={() => window.scrollTo(0, 0)}
                                    />
                                )}
                            />
                        </div>
                    </React.Suspense>
                </Router>
            </>
        );
    }
}

export default App;
