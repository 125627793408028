import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
  NavItem,
} from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from "react-i18next";

class HeaderContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(isOpen) {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const t = this.props.t;

    return (
      <>
        <header
          className="site-header sticky-top"
          fixed="top"
          style={{ background: "#fff" }}
        >
          <div id="header-wrap">
            <Container>
              <Row>
                <Col className="d-flex align-items-center justify-content-between">
                  <NavbarBrand
                    className="logo text-dark h2 mb-0"
                    tag={Link}
                    to="/"
                  >
                    CRWD
                    <span className="text-primary font-weight-bold">
                      Network
                    </span>
                    <div className="zeor21logo text-primary">
                      a product of SimplyTokenized
                    </div>
                  </NavbarBrand>
                  <Navbar light expand="lg" className="ml-auto mr-auto">
                    <NavbarToggler onClick={this.toggle}>
                      <span className="navbar-toggler-icon"></span>
                    </NavbarToggler>
                    <Collapse isOpen={this.state.isOpen} navbar id="navbarNav">
                      <Nav navbar>
                        <NavItem>
                          <NavLink className="nav-link" to="/crwdnetwork">
                            {t("Solutions")}
                          </NavLink>
                        </NavItem>
                        {/* <UncontrolledDropdown nav inNavbar>
                                                    <DropdownToggle nav caret>
                                                        {t('Solutions')}
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <DropdownItem tag={Link} to="/crwdnetwork">
                                                            {t('CRWDNetwork')}
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            tag={Link}
                                                            to="/crwdnetwork"
                                                        >
                                                            {t('Corporate Bond')}
                                                        </DropdownItem>
                                                        <DropdownItem divider />
                                                        <DropdownItem
                                                            tag={Link}
                                                            to="/customsolution"
                                                        >
                                                            {t('Custom Solution')}
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown> */}
                        {/* <NavItem>
                          <NavLink className="nav-link" to="/projects">
                            {t("Projects")}
                          </NavLink>
                        </NavItem> */}
                        <NavItem>
                          <NavLink className="nav-link" to="/pricing">
                            {t("Pricing")}
                          </NavLink>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle nav caret>
                            {t("Ecosystem")}
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem tag={Link} to="/target2022">
                              {t("Target 2022")}
                            </DropdownItem>
                            <DropdownItem tag={Link} to="/governance">
                              {t("Governance")}
                            </DropdownItem>
                            {/* <DropdownItem tag={Link} to="/provider">
                              {t("Provider List")}
                            </DropdownItem>
                            <DropdownItem
                              tag={Link}
                              to="/governance/workinggroups"
                            >
                              {t("Working Groups")}
                            </DropdownItem> */}
                            {/* <DropdownItem tag={Link} to="/governance/networkproposals">
                                                            {t('Network Proposals')}
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/governance/proposals">
                                                            {t('Governance Proposals')}
                                                        </DropdownItem> */}
                            <DropdownItem tag={Link} to="/contact">
                              {t("Partner Application")}
                            </DropdownItem>
                            {/* <DropdownItem divider />
                            <DropdownItem tag={Link} to="/getcrwt">
                              {t("Buy CRWDToken")}
                            </DropdownItem> */}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <NavItem>
                          <NavLink className="nav-link" to="/contact">
                          {t("Kontakt")}
                          </NavLink>
                        </NavItem>
                        {/* <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle nav caret>
                            {t("Resources")}
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem href="https://medium.com/crwdnetwork">
                              {t("Blog")}
                            </DropdownItem>
                            <DropdownItem tag={Link} to="/resources/videos">
                              {t("Videos")}
                            </DropdownItem>
                            <DropdownItem tag={Link} to="/contact">
                              {t("Kontakt")}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}
                        <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle nav caret>
                            {i18n.language.toUpperCase().substring(0, 2)}
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem
                              onClick={() => i18n.changeLanguage("en")}
                            >
                              {t("common:english")}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => i18n.changeLanguage("de")}
                            >
                              {t("common:german")}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Nav>
                    </Collapse>
                  </Navbar>
                  <Button
                    color="primary"
                    className="ml-8 d-none d-lg-block"
                    tag={Link}
                    to="/getcrwt"
                  >
                    {t("CRWDToken")}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </header>
      </>
    );
  }
}

export default withTranslation("menu")(HeaderContainer);
