import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        hasError: false,
        error: '',
        errorInfo: ''
      };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      this.setState({ error, errorInfo });
      console.log(JSON.stringify(error) + " - " + JSON.stringify(errorInfo,2,2));
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <>
          <h1>Something went wrong.</h1>
          </>
        );
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;