import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HeaderContainer from "./header.container";
import FooterContainer from "./footer.container";
import { withTranslation } from "react-i18next";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";

const HomeView = React.lazy(() => import("../views/home.view"));
const SolutionsStartupBondView = React.lazy(() =>
  import("../views/solutions.startupbond.view")
);
const SolutionsCRWDNetworkView = React.lazy(() =>
  import("../views/solutions.crwdnetwork.view")
);
const SolutionsCorporateBondView = React.lazy(() =>
  import("../views/solutions.corporatebond.view")
);
const SolutionsCustomView = React.lazy(() =>
  import("../views/solutions.custom.view")
);
const ProjectsView = React.lazy(() => import("../views/projects.view"));
const GetCRWTView = React.lazy(() => import("../views/get.crwt.view"));
const PricingView = React.lazy(() => import("../views/pricing.view"));
const EcosystemGovernanceView = React.lazy(() =>
  import("../views/ecosystem.governance.view")
);
const EcosystemProvderlistView = React.lazy(() =>
  import("../views/ecosystem.providerlist.view")
);
const EcosystemTarget2022View = React.lazy(() =>
  import("../views/ecosystem.target2022.view")
);
const TOSView = React.lazy(() => import("../views/tos.view"));
const PrivacyView = React.lazy(() => import("../views/privacy.view"));
const ImpressView = React.lazy(() => import("../views/impress.view"));
const ContactView = React.lazy(() => import("../views/contact.view"));
const ResourcesVideosView = React.lazy(() =>
  import("../views/resources.videos.view")
);
const ContactRegisterBetaView = React.lazy(() =>
  import("../views/contact.registerbeta.view")
);
const NewsletterConfirmView = React.lazy(() =>
  import("../views/newsletter.confirm.view")
);
const NewsletterUnsubscribeView = React.lazy(() =>
  import("../views/newsletter.unsubscribe.view")
);
const EcosystemGovernanceWorkingGroupsView = React.lazy(() =>
  import("../views/ecosystem.governance.workinggroups.view")
);
const EcosystemGovernanceNetnworkproposalsView = React.lazy(() =>
  import("../views/ecosystem.governance.networkproposals.view")
);
const EcosystemGovernanceProposalsView = React.lazy(() =>
  import("../views/ecosystem.governance.governanceproposals.view")
);

class LayoutContainer extends Component {
  componentDidMount() {
    const cookieConsent = Cookies.get("CookieConsent");
    if (cookieConsent) {
      ReactGA.initialize([
        { trackingId: process.env.REACT_APP_GA_CODE, debug: true },
      ]);
      ReactGA.pageview(window.location.pathname + window.location.search);
      Sentry.init({
        dsn: "https://16e02153472b4456be4941435d430e5a@sentry.io/1856527",
      });
    }
  }

  onAcceptCookie() {
    ReactGA.initialize([
      { trackingId: process.env.REACT_APP_GA_CODE, debug: true },
    ]);
    ReactGA.pageview(window.location.pathname + window.location.search);
    Sentry.init({
      dsn: "https://16e02153472b4456be4941435d430e5a@sentry.io/1856527",
    });
  }

  onDeclineCookie() {
    Cookies.remove("_ga");
    Cookies.remove("_gid");
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <HeaderContainer />
        <Switch>
          <Route
            path="/home"
            name="Home"
            render={(props) => <HomeView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/startupbond"
            name="Home"
            render={(props) => <SolutionsStartupBondView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/crwdnetwork"
            name="Home"
            render={(props) => <SolutionsCRWDNetworkView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/corporatebond"
            name="Home"
            render={(props) => <SolutionsCorporateBondView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/customsolution"
            name="Home"
            render={(props) => <SolutionsCustomView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/projects"
            name="Home"
            render={(props) => <ProjectsView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/getcrwt"
            name="Home"
            render={(props) => <GetCRWTView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/pricing"
            name="Home"
            render={(props) => <PricingView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/governance"
            name="Home"
            exact
            render={(props) => <EcosystemGovernanceView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/governance/workinggroups"
            name="Home"
            exact
            render={(props) => (
              <EcosystemGovernanceWorkingGroupsView {...props} />
            )}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/governance/networkproposals"
            name="Home"
            exact
            render={(props) => (
              <EcosystemGovernanceNetnworkproposalsView {...props} />
            )}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/governance/proposals"
            name="Home"
            exact
            render={(props) => <EcosystemGovernanceProposalsView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/provider"
            name="Home"
            render={(props) => <EcosystemProvderlistView {...props} />}
            // onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/target2022"
            name="Home"
            render={(props) => <EcosystemTarget2022View {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/tos"
            name="Home"
            render={(props) => <TOSView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/privacy"
            name="Home"
            render={(props) => <PrivacyView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/impress"
            name="Home"
            render={(props) => <ImpressView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/contact"
            name="Home"
            render={(props) => <ContactView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/beta"
            name="Home"
            render={(props) => <ContactRegisterBetaView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/resources/videos"
            name="Home"
            render={(props) => <ResourcesVideosView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/newsletter/confirm"
            name="Home"
            render={(props) => <NewsletterConfirmView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Route
            path="/newsletter/unsubscribe"
            name="Home"
            render={(props) => <NewsletterUnsubscribeView {...props} />}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <Redirect from="/" to="/home" />
        </Switch>
        <FooterContainer />
        <CookieConsent
          buttonClasses="btn btn-primary"
          enableDeclineButton
          flipButtons
          onAccept={this.onAcceptCookie}
          onDecline={this.onDeclineCookie}
          buttonText={t("Cookie.Agree")}
          declineButtonText={t("Cookie.Decline")}
        >
          {t("Cookie.Disclaimer")}
        </CookieConsent>
      </>
    );
  }
}

export default withTranslation("common")(LayoutContainer);
