import React, { Component } from "react";
import { Container, Row, Col, Nav, NavItem, Button } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { FacebookIcon } from "react-line-awesome";

import bgimage from "../assets/images/03.png";
// import NewsletterForm from "../forms/newsletter.form";

class FooterContainer extends Component {
  render() {
    const t = this.props.t;

    return (
      <>
        <footer
          className="py-11 bg-primary position-relative"
          style={{ backgroundImage: "url(" + bgimage + ")" }}
        >
          <div
            className="shape-1"
            style={{ height: "150px", overflow: "hidden" }}
          >
            <svg
              viewBox="0 0 500 150"
              preserveAspectRatio="none"
              style={{ height: "100%", width: "100%" }}
            >
              <path
                d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
                style={{ stroke: "none", fill: "#fff" }}
              ></path>
            </svg>
          </div>
          <Container className="mt-11">
            <Row>
              <Col lg={5} xl={4} md={12} className="mr-auto mb-6 mb-lg-0">
                <div className="subscribe-form bg-warning-soft p-5 rounded">
                  <h5 className="mb-4 text-white">{t("Event.Title")}</h5>
                  {/* <NewsletterForm /> */}
                  <small className="text-light">{t("Event.Description")}</small>

                  <Button
                      tag={Link}
                      color="outline-light"
                      className="btn-block mt-5"
                      to={{ pathname: "https://www.eventbrite.at/e/the-presentation-of-the-crwdnetwork-tickets-223335702097" }}
                      target="_blank"
                    >
                      {t("Event.Action")}
                    </Button>
                  {/* <small className="text-light">{t("Newsletter.Text")}</small> */}
                </div>
              </Col>
              <Col lg={6} xl={7} md={12}>
                <Row>
                  <Col sm={4} sx={12} className="navbar-dark">
                    
                  </Col>
                  <Col sm={4} sx={12} className="mt-6 mt-sm-0 navbar-dark">
                  <h5 className="mb-4 text-white">{t("Resources")}</h5>
                    <Nav navbar className="list-unstyled mb-0">
                      <NavItem className="mb-3">
                        <NavLink className="nav-link" to="/crwdnetwork">
                          {t("Solutions")}
                        </NavLink>
                      </NavItem>
                      <NavItem className="mb-3">
                        <NavLink className="nav-link" to="/pricing">
                          {t("Pricing")}
                        </NavLink>
                      </NavItem>
                      <NavItem className="mb-3">
                        <a
                          className="nav-link"
                          href="https://medium.com/crwdnetwork"
                        >
                          {t("Blog")}
                        </a>
                      </NavItem>
                    </Nav>
                    {/* <h5 className="mb-4 text-white">{t("Solutions")}</h5>
                    <Nav navbar className="list-unstyled mb-0">
                      <NavItem className="mb-3">
                        <NavLink className="nav-link" to="/startupbond">
                          {t("Startup Venture Bond")}
                        </NavLink>
                      </NavItem>
                      <NavItem className="mb-3">
                        <NavLink className="nav-link" to="/corporatebond">
                          {t("Corporate Bond")}
                        </NavLink>
                      </NavItem>
                      <NavItem className="mb-3">
                        <NavLink className="nav-link" to="/customsolution">
                          {t("Custom Solution")}
                        </NavLink>
                      </NavItem>
                      <NavItem className="mb-3">
                        <NavLink className="nav-link" to="/pricing">
                          {t("Pricing")}
                        </NavLink>
                      </NavItem>
                    </Nav> */}
                  </Col>
                  <Col sm={4} sx={12} className="mt-6 mt-sm-0 navbar-dark">
                    <h5 className="mb-4 text-white">{t("Ecosystem")}</h5>
                    <Nav navbar className="list-unstyled mb-0">
                      <NavItem className="mb-3">
                        <NavLink className="nav-link" to="/target2022">
                          {t("Target 2022")}
                        </NavLink>
                      </NavItem>
                      <NavItem className="mb-3">
                        <NavLink className="nav-link" to="/governance">
                          {t("Governance")}
                        </NavLink>
                      </NavItem>
                      {/* <NavItem className="mb-3">
                                                <NavLink className="nav-link" to="/provider">
                                                    {t('Provider List')}
                                                </NavLink>
                                            </NavItem> */}
                      <NavItem className="mb-3">
                        <NavLink className="nav-link" to="/contact">
                          {t("Partner Application")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col sm={6} sx={12}>
                    <NavLink className="footer-logo text-white h2 mb-0" to="/">
                      CRWD<span className="font-weight-bold">Network</span>
                    </NavLink>
                  </Col>
                  <Col sm={6} sx={12} className="mt-6 mt-sm-0">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <a
                          className="text-light ic-2x"
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.facebook.com/crwdnetwork/"
                        >
                          <FacebookIcon />
                        </a>
                      </li>
                      {/* <li className="list-inline-item">
                                                <a className="text-light ic-2x" href="/">
                                                    <TwitterIcon />
                                                </a>
                                            </li> */}
                      {/* <li className="list-inline-item">
                        <a
                          className="text-light ic-2x"
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.linkedin.com/company/conda-unternehmensberatungs-gmbh/"
                        >
                          <LinkedinIcon />
                        </a>
                      </li> */}
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="text-white text-leftnmt-8">
              <Col>
                <hr className="mb-2" />
                <Button
                  color="Link"
                  tag={Link}
                  size="sm"
                  className="text-white"
                  to="/impress"
                >
                  {t("common:Copyright")} {t("glossary:PAGE-YEAR")}{" "}
                  {t("glossary:PAGE-COMPANY")}
                </Button>{" "}
                |
                <Button
                  color="Link"
                  tag={Link}
                  size="sm"
                  className="text-white"
                  to="/impress"
                >
                  {t("Impress")}
                </Button>
                |
                <Button
                  color="Link"
                  tag={Link}
                  size="sm"
                  className="text-white"
                  to="/privacy"
                >
                  {t("Privacy")}
                </Button>
                {/* |
                                <Button
                                    color="Link"
                                    tag={Link}
                                    size="sm"
                                    className="text-white"
                                    to="/tos"
                                >
                                    {t('TOS')}
                                </Button> */}
                |
                <Button
                  color="Link"
                  tag={Link}
                  size="sm"
                  className="text-white"
                  to="/contact"
                >
                  {t("Contact")}
                </Button>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default withTranslation("menu")(FooterContainer);
