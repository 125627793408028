import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/theme-plugin.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // initialized i18next instance
import ErrorBoundary from './libs/error.boundary';

ReactDOM.render(
    <I18nextProvider i18n={ i18n }>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </I18nextProvider>, 
    document.getElementById('root')); 

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
